import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {CartProduct} from '../models/cart-product';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  KEY_CURRENT_LANG = 'currentLang';
  KEY_CART_PRODUCTS = 'cartProducts';

  constructor(private translateService: TranslateService) {
    this.KEY_CURRENT_LANG = 'currentLang' + environment.code.toLowerCase().replace(/\s+/g, ' ');
  }

  getCurrentLang(): string {
    const data = localStorage.getItem(this.KEY_CURRENT_LANG);
    if (!data) {
      return null;
    }

    return data;
  }

  setCurrentLang(lang): void {
    localStorage.setItem(this.KEY_CURRENT_LANG, lang);
  }

  updateCartProductKey(slug: string): void {
    this.KEY_CART_PRODUCTS = 'cartProducts' + slug.toLowerCase().replace(/\s+/g, ' ') + environment.code.toLowerCase().replace(/\s+/g, ' ');
  }

  getCartProducts(slug: string): CartProduct[] {
    this.updateCartProductKey(slug);
    const data = localStorage.getItem(this.KEY_CART_PRODUCTS);
    if (!data) {
      return [];
    } else {
      try {
        const cartProducts = JSON.parse(data);
        cartProducts.map(cartProduct => CartProduct.fromJSON(cartProduct));
        return cartProducts;
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  }

  setCartProducts(cartProducts: CartProduct[], slug: string): void {
    this.updateCartProductKey(slug);
    localStorage.setItem(this.KEY_CART_PRODUCTS, JSON.stringify(cartProducts));
  }
}
