import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {StorageService} from './storage.service';
import {Shop} from '../models/shop';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  shopConfigUpdated: EventEmitter<any> = new EventEmitter();
  shop = new Shop();

  constructor(private translateService: TranslateService,
              private storageService: StorageService,
              private apiService: ApiService) { }

  load(): Promise<any> {
    return Promise.all([this.initLanguage()]);
  }

  initLanguage(): Promise<any> {
    return new Promise((resolve) => {
      this.translateService.addLangs(['en', 'tc', 'sc']);
      this.translateService.setDefaultLang('en');

      const storedLang = this.storageService.getCurrentLang();
      this.setLanguage(storedLang ? storedLang : 'en', false);
      resolve(true);
    });
  }

  setLanguage(lang, store = true): void {
    this.translateService.use(lang);
    if (lang === 'tc') {
      moment.locale('zh-hk');
    } else if (lang === 'sc') {
      moment.locale('zh-hk');
    } else {
      moment.locale('en');
    }

    if (store)
      this.storageService.setCurrentLang(lang);
  }
}
