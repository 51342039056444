import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ShopService} from '../../../services/shop.service';
import {Shop} from '../../../models/shop';
import {Product} from '../../../models/product';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {CartProduct} from '../../../models/cart-product';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  slug = '';
  shop: Shop;
  products: Product[];
  currency: string;
  cartItems: CartProduct[];

  // Pagination
  page = 1;
  maxPage = 1;
  pageSize = 12;
  pages = [1];
  sortBy = 'default';
  keyword = '';

  shopUpdateSubscription;
  cartItemsUpdateSubscription;

  constructor(private router: Router,
              private shopService: ShopService,
              private shoppingCartService: ShoppingCartService) { }

  ngOnInit(): void {
    this.slug = this.shopService.slug;
    this.shop = this.shopService.shop;
    this.products = this.shopService.products;
    this.currency = this.shopService.currency;
    this.updatePagination();
    this.shopUpdateSubscription = this.shopService.shopUpdated.subscribe(data => {
      this.slug = data.slug;
      this.shop = data.shop;
      this.products = data.products;
      this.currency = data.currency;
      this.updatePagination();
    });

    this.cartItems = this.shoppingCartService.all(this.slug);
    this.cartItemsUpdateSubscription = this.shoppingCartService.cartItemsChanged.subscribe(cartItems => this.cartItems = cartItems);
  }

  ngOnDestroy(): void {
    this.shopUpdateSubscription.unsubscribe();
  }

  updatePagination(): void {
    this.maxPage = Math.max(1, Math.ceil(this.products.length / this.pageSize));
    this.pages = Array(this.maxPage).fill(0).map((x, i) => i + 1);
  }

  addToCart(product: Product): void {
    if (this.isInCart(product)) {
      this.router.navigate(['/' + this.slug + '/cart']);
    } else {
      this.shoppingCartService.add(product, 1, this.slug);
    }
  }

  productDetail(product: Product): void {
    this.router.navigate(['/' + this.slug + '/product/' + product.id]);
  }

  isInCart(product: Product): boolean {
    return this.cartItems.filter(cartItem => cartItem.product.id === product.id).length > 0;
  }

  prevPage(e): void {
    e.preventDefault();
    if (this.page > 1) this.page--;
  }

  nextPage(e): void {
    e.preventDefault();
    if (this.page < this.maxPage) this.page++;
  }

  changePage(i, e): void {
    e.preventDefault();
    this.page = i;
  }
}
