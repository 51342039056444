import {EventEmitter, Injectable} from '@angular/core';
import {Shop} from '../models/shop';
import {ApiService} from './api.service';
import {Product} from '../models/product';
import {ShoppingCartService} from './shopping-cart.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  initialized = false;
  shopUpdated: EventEmitter<any> = new EventEmitter();
  slug: string;
  shop: Shop;
  products: Product[] = [];
  currency: string;
  messenger_code: string | null;

  constructor(private apiService: ApiService) { }

  refresh(slug: string, success?, error?): void {
    this.slug = slug;
    this.apiService.method('GET', 'shop/' + slug, null, null, data => {
      this.shop = Shop.fromJSON(data.data.shop);
      this.products = [];
      data.data.products.forEach(product => {
        this.products.push(Product.fromJSON(product));
      });
      this.currency = data.data.currency;
      this.messenger_code = data.data.messenger_code;
      this.initialized = true;
      if (success) success(this.shop, this.products, this.currency);
      this.shopUpdated.emit({slug: this.slug, shop: this.shop, products: this.products, currency: this.currency, messenger_code: this.messenger_code});
    }, err => {
      console.error(err);
      if (error) error(err);
    });
  }

  checkout(input, success?, error?): void {
    this.apiService.method('POST', 'shop/' + this.slug, null, input, data => {
      if (success) success(data.data.payment.url);
    }, err => {
      if (error) error(err);
    });
  }
}
