import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShopService} from '../../../services/shop.service';
import {Shop} from '../../../models/shop';
import {Product} from '../../../models/product';
import {CartProduct} from '../../../models/cart-product';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {CallingCodes} from '../../../calling-code';
import {PopupService} from '../../../services/popup.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  slug: string;
  shop: Shop;
  products: Product[];
  currency: string;
  cartItems: CartProduct[];
  callingCodes = [];
  selectedCallingCode = '852';

  name: string;
  channel = '';
  contactNumber: string;
  submitted = false;
  submitting = false;

  shopUpdateSubscription;
  cartItemsUpdateSubscription;

  constructor(private shopService: ShopService,
              private shoppingCartService: ShoppingCartService,
              private popupService: PopupService,
              private translateService: TranslateService,
              private apiService: ApiService) {
    this.callingCodes = CallingCodes.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit(): void {
    this.updateData(this.shopService);
    this.shopUpdateSubscription = this.shopService.shopUpdated.subscribe(data => {
      this.updateData(data);
    });

    this.cartItemsUpdateSubscription = this.shoppingCartService.cartItemsChanged.subscribe(cartItems => {
      this.cartItems = cartItems;
    });
  }

  ngOnDestroy(): void {
    this.shopUpdateSubscription.unsubscribe();
    this.cartItemsUpdateSubscription.unsubscribe();
  }

  updateData(data): void {
    this.slug = data.slug;
    this.shop = data.shop;
    this.products = data.products;
    this.currency = data.currency;
    if (this.slug) {
      this.cartItems = this.shoppingCartService.all(this.slug);
    }
  }

  updateQty(cartItem: CartProduct): void {
    if (cartItem.product === null || cartItem.product === undefined) return;
    if (!Number.isInteger(cartItem.quantity)) {
      cartItem.quantity = 1;
    } else if (cartItem.quantity <= 0) {
      this.removeProduct(cartItem);
      return;
    }
    this.shoppingCartService.updateCart(cartItem.product, cartItem.quantity, this.slug);
  }

  removeProduct(cartItem: CartProduct, e?): void {
    if (e) e.preventDefault();
    if (cartItem.product === null || cartItem.product === undefined) return;
    this.translateService.get(['message.do-you-confirm-to-remove-this-product-from-your-cart', 'message.confirm', 'message.cancel']).subscribe(translations => {
      this.popupService.show({
        template: translations['message.do-you-confirm-to-remove-this-product-from-your-cart'],
        buttons: [
          {
            text: translations['message.confirm'],
            callback: () => {
              this.shoppingCartService.delete(cartItem.product, this.slug);
            }
          },
          {
            text: translations['message.cancel'],
            callback: () => {
              if (!this.qtyIsValid(cartItem)) {
                cartItem.quantity = 1;
                this.shoppingCartService.updateCart(cartItem.product, cartItem.quantity, this.slug);
              }
            }
          }
        ]
      });
    });
  }

  qtyIsValid(cartItem: CartProduct): boolean {
    return Number.isInteger(cartItem.quantity) && cartItem.quantity > 0;
  }

  isNotEmpty(str: any): boolean {
    return str !== null && str !== undefined && str !== '';
  }

  get total(): number {
    let total = 0;
    this.cartItems.forEach(item => {
      if (item.product) {
        total += item.product.price * item.quantity;
      }
    });
    return total;
  }

  get total_str(): string {
    return this.total.toLocaleString();
  }

  submit(): void {
    this.submitted = true;
    if (!this.isNotEmpty(this.name) || !this.isNotEmpty(this.channel) || !this.isNotEmpty(this.contactNumber)) return;
    this.submitting = true;
    const products = [];
    this.cartItems.forEach(item => {
      products.push(CartProduct.toStorage(item));
    });
    const data = {
      channel: this.channel,
      number: this.selectedCallingCode + this.contactNumber,
      name: this.name,
      total_price: this.total,
      products
    };
    this.shopService.checkout(data, url => {
      window.location.href = url;
      this.shoppingCartService.emptyCart(this.slug);
    }, error => {
      console.error(error);
      this.submitting = false;
    });
  }

}
