import {Directive, ElementRef, Input} from '@angular/core';

declare var $;

@Directive({
    selector: '[appLoadingButton]'
})
export class LoadingButtonDirective {

    constructor(private el: ElementRef) {
        setTimeout(() => {
            $(this.el.nativeElement).addClass("ld-ext-right").append($("<div class='ld ld-ring ld-spin'>"));
        }, 0);
    }

    @Input('appLoadingButton')
    set appLoadingButton(value: boolean) {
        if (value)
            $(this.el.nativeElement).addClass("running").prop("disabled", true);
        else
            $(this.el.nativeElement).removeClass("running").prop("disabled", false);
    }

}
