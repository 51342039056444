import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '../../services/app-config.service';
import {ShopService} from '../../services/shop.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  currentLanguage = 'en';
  messenger_code: string | null;

  languageChangeSubscription;
  shopUpdateSubscription;

  constructor(private translateService: TranslateService,
              private appConfigService: AppConfigService,
              private shopService: ShopService) { }

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;
    this.languageChangeSubscription = this.translateService.onLangChange.subscribe(event => {
      this.currentLanguage = event.lang;
    });

    this.messenger_code = this.shopService.messenger_code;
    this.appendPlugin();
    this.shopUpdateSubscription = this.shopService.shopUpdated.subscribe(data => {
      this.messenger_code = data.messenger_code;
      this.appendPlugin();
    });
  }

  appendPlugin(): void {
    if (!this.messenger_code) return;

    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.src = environment.webPluginJS;
    newScript.setAttribute(environment.pluginDataAttr, this.messenger_code);
    newScript.setAttribute('data-server', environment.webPluginServer);
    document.body.appendChild(newScript);
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
    this.shopUpdateSubscription.unsubscribe();
  }

  changeLang(lang: string, e: Event): void {
    e.preventDefault();
    this.appConfigService.setLanguage(lang, true);
  }
}
