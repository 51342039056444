<router-outlet></router-outlet>

<div id="alert-popup" class="cd-popup" role="alert" [class.is-visible]="popup">
  <div *ngIf="popup" class="cd-popup-container">
    <div id="alert-popup-body">
      <span *ngIf="popup.template" [innerHTML]="popup.template"></span>
      <div *ngIf="popup.code == 'DELN_0001'">
        <div style="font-weight: 700">{{'main.deletion-error' | translate}}</div>
        <div style="text-align: left">
          <div *ngFor="let error of popup.errors" style="margin-top: 20px">
            <div>{{ error.message }}</div>
            <ul>
              <li *ngFor="let obj of error.objects">{{ obj }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="popup.code == 'GENL_0004'">
        <div [style.font-weight]="popup.errors.errors && popup.errors.errors.length > 0 ? 700 : 400">{{ popup.errors.text }}</div>
        <div style="text-align: left">
          <div *ngFor="let error of popup.errors.errors" style="margin-top: 20px">
            <div>{{ error.message }}</div>
            <ul>
              <li *ngFor="let obj of error.objects">{{ obj }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ul class="cd-buttons">
      <li *ngFor="let button of popup.buttons" (click)="popupButtonClick(button)">
        <a [ngClass]="button.style">{{ button.text }}</a>
      </li>
    </ul>
  </div>
</div>
