export class Product {
  id: number;
  name: string;
  description: string;
  price: number;
  code: string;
  image: string;
  thumbnail: string;
  image_resolution_x: number;
  image_resolution_y: number;

  static fromJSON(json: any): Product {
    const object = new Product();
    object.id = json.id;
    object.name = json.name;
    object.description = json.description;
    object.price = json.price;
    object.code = json.code;
    object.image = json.image;
    object.thumbnail = json.thumbnail;
    object.image_resolution_x = json.image_resolution_x;
    object.image_resolution_y = json.image_resolution_y;
    return object;
  }

  get image_preview_width() {
    if (this.image_resolution_x > this.image_resolution_y) {
      return Math.min(300, this.image_resolution_x);
    } else {
      return this.image_resolution_x / this.image_resolution_y * Math.min(300, this.image_resolution_y);
    }
  }

  get image_preview_height() {
    if (this.image_resolution_y > this.image_resolution_x) {
      return Math.min(300, this.image_resolution_y);
    } else {
      return this.image_resolution_y / this.image_resolution_x * Math.min(300, this.image_resolution_x);
    }
  }
}
