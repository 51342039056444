import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

declare var $;

@Injectable()
export class PopupService {

    popupChanged: EventEmitter<any> = new EventEmitter();

    constructor(private translateService: TranslateService) {
    }

    show(config: any) {
        this.popupChanged.emit(config);
    }

    showConfirm(message): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.translateService.get(['form.confirm', 'form.cancel']).subscribe(res => {
                this.show({
                    template: message,
                    buttons: [
                        {
                            text: res['form.confirm'],
                            callback: () => {
                                resolve(true);
                            }
                        },
                        {
                            text: res['form.cancel'],
                            callback: () => {
                                resolve(false);
                            }
                        }
                    ]
                });
            })
        });
    }

    showConfirmDelete(type: string, name: string = ""): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.translateService.get(['messages.sure-to-delete', type]).subscribe(res => {
                let message = res['messages.sure-to-delete'] + res[type].toLowerCase() + (name.length == 0 ? '' : ` ${name}`) + '?';
                this.showConfirm(message).then(x => resolve(x));
            })
        });

    }
}
