<div class="shop-item" (click)="detail(product, $event)">
  <div class="shop-item-image" [style.background-image]="product.thumbnail ? 'url(' + product.thumbnail + ')' : 'url(assets/images/product-image-placeholder.png)'">
    <div class="shop-item-detail">
      <a *ngIf="!isInCart" class="btn btn-round btn-b" (click)="addToCart(product, $event)">
        <mat-icon>shopping_cart</mat-icon>
        {{ 'common.add-to-cart' | translate }}
      </a>
      <a *ngIf="isInCart" class="btn btn-round btn-b view-in-cart" (click)="addToCart(product, $event)">
        <mat-icon class="green">checkmark</mat-icon>
        {{ 'common.view-in-cart' | translate }}
      </a>
    </div>
  </div>
  <h4 class="shop-item-title font-alt"><a>{{ product.name }}</a></h4>{{ currency }} {{ product.price.toLocaleString() }}
</div>
