<footer class="footer bg-dark">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <p class="copyright font-alt">&copy; {{ year }}&nbsp;<a class="primary-color" href="https://islash.io" target="_blank">iSlash.io</a>, All Rights Reserved</p>
      </div>
      <div class="col-sm-6">
        <div class="languages">
          <a [class.primary-color]="currentLanguage === 'en'" href="#" (click)="changeLang('en', $event)">EN</a>
          <a [class.primary-color]="currentLanguage === 'tc'" href="#" (click)="changeLang('tc', $event)">繁</a>
          <a [class.primary-color]="currentLanguage === 'sc'" href="#" (click)="changeLang('sc', $event)">簡</a>
        </div>
<!--        <div class="footer-social-links"><a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-dribbble"></i></a><a href="#"><i class="fa fa-skype"></i></a>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</footer>
<div class="scroll-up"><a href="#totop"><i class="fa fa-angle-double-up"></i></a></div>
