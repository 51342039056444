import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {HomeComponent} from './page/home/home.component';
import {PageComponent} from './page/page.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Location} from '@angular/common';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as moment from 'moment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {StorageService} from '../services/storage.service';
import {AppConfigService} from '../services/app-config.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {ShoppingCartService} from '../services/shopping-cart.service';
import {ApiService} from '../services/api.service';
import {PopupService} from '../services/popup.service';
import {MatDialogModule} from '@angular/material/dialog';
import {ShopService} from '../services/shop.service';
import { ProductListItemComponent } from './components/product-list-item/product-list-item.component';
import { ProductComponent } from './page/product/product.component';
import {FormsModule} from '@angular/forms';
import { CartComponent } from './page/cart/cart.component';
import {NumberOnlyDirective} from '../directives/number-only.directive';
import {LoadingButtonDirective} from '../directives/loading-button.directive';
import {ProductFilterPipe} from '../pipes/product-filter.pipe';
import {ProductSortPipe} from '../pipes/product-sort.pipe';

export function HttpLoaderFactory(httpClient: HttpClient, location: Location) {
  return new TranslateHttpLoader(httpClient, location.prepareExternalUrl('/assets/i18n/'), '.json?t=' + moment().unix());
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoadingButtonDirective,
    PageComponent,
    ProductFilterPipe,
    ProductSortPipe,
    NotFoundComponent,
    NumberOnlyDirective,
    ProductListItemComponent,
    ProductComponent,
    CartComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatBadgeModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, Location]
      }
    })
  ],
  providers: [
    ApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: AppConfigService) => () => initService.load(),
      multi: true,
      deps: [AppConfigService]
    },
    PopupService,
    ShoppingCartService,
    ShopService,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
