export var CallingCodes = [
    {
        "country": "AF",
        "name": "Afghanistan",
        "code": "93"
    },
    {
        "country": "AL",
        "name": "Albania",
        "code": "355"
    },
    {
        "country": "DZ",
        "name": "Algeria",
        "code": "213"
    },
    {
        "country": "AS",
        "name": "American Samoa",
        "code": "1"
    },
    {
        "country": "AD",
        "name": "Andorra",
        "code": "376"
    },
    {
        "country": "AO",
        "name": "Angola",
        "code": "244"
    },
    {
        "country": "AI",
        "name": "Anguilla",
        "code": "1"
    },
    {
        "country": "AG",
        "name": "Antigua",
        "code": "1"
    },
    {
        "country": "AR",
        "name": "Argentina",
        "code": "54"
    },
    {
        "country": "AM",
        "name": "Armenia",
        "code": "374"
    },
    {
        "country": "AW",
        "name": "Aruba",
        "code": "297"
    },
    {
        "country": "AU",
        "name": "Australia",
        "code": "61"
    },
    {
        "country": "AT",
        "name": "Austria",
        "code": "43"
    },
    {
        "country": "AZ",
        "name": "Azerbaijan",
        "code": "994"
    },
    {
        "country": "BH",
        "name": "Bahrain",
        "code": "973"
    },
    {
        "country": "BD",
        "name": "Bangladesh",
        "code": "880"
    },
    {
        "country": "BB",
        "name": "Barbados",
        "code": "1"
    },
    {
        "country": "BY",
        "name": "Belarus",
        "code": "375"
    },
    {
        "country": "BE",
        "name": "Belgium",
        "code": "32"
    },
    {
        "country": "BZ",
        "name": "Belize",
        "code": "501"
    },
    {
        "country": "BJ",
        "name": "Benin",
        "code": "229"
    },
    {
        "country": "BM",
        "name": "Bermuda",
        "code": "1"
    },
    {
        "country": "BT",
        "name": "Bhutan",
        "code": "975"
    },
    {
        "country": "BO",
        "name": "Bolivia",
        "code": "591"
    },
    {
        "country": "BA",
        "name": "Bosnia and Herzegovina",
        "code": "387"
    },
    {
        "country": "BW",
        "name": "Botswana",
        "code": "267"
    },
    {
        "country": "BR",
        "name": "Brazil",
        "code": "55"
    },
    {
        "country": "IO",
        "name": "British Indian Ocean Territory",
        "code": "246"
    },
    {
        "country": "VG",
        "name": "British Virgin Islands",
        "code": "1"
    },
    {
        "country": "BN",
        "name": "Brunei",
        "code": "673"
    },
    {
        "country": "BG",
        "name": "Bulgaria",
        "code": "359"
    },
    {
        "country": "BF",
        "name": "Burkina Faso",
        "code": "226"
    },
    {
        "country": "BI",
        "name": "Burundi",
        "code": "257"
    },
    {
        "country": "KH",
        "name": "Cambodia",
        "code": "855"
    },
    {
        "country": "CM",
        "name": "Cameroon",
        "code": "237"
    },
    {
        "country": "CA",
        "name": "Canada",
        "code": "1"
    },
    {
        "country": "CV",
        "name": "Cape Verde",
        "code": "238"
    },
    {
        "country": "KY",
        "name": "Cayman Islands",
        "code": "1"
    },
    {
        "country": "CF",
        "name": "Central African Republic",
        "code": "236"
    },
    {
        "country": "TD",
        "name": "Chad",
        "code": "235"
    },
    {
        "country": "CL",
        "name": "Chile",
        "code": "56"
    },
    {
        "country": "CN",
        "name": "China",
        "code": "86"
    },
    {
        "country": "CO",
        "name": "Colombia",
        "code": "57"
    },
    {
        "country": "KM",
        "name": "Comoros",
        "code": "269"
    },
    {
        "country": "CK",
        "name": "Cook Islands",
        "code": "682"
    },
    {
        "country": "CR",
        "name": "Costa Rica",
        "code": "506"
    },
    {
        "country": "HR",
        "name": "Croatia",
        "code": "385"
    },
    {
        "country": "CU",
        "name": "Cuba",
        "code": "53"
    },
    {
        "country": "CY",
        "name": "Cyprus",
        "code": "357"
    },
    {
        "country": "CZ",
        "name": "Czech Republic",
        "code": "420"
    },
    {
        "country": "CD",
        "name": "Democratic Republic of the Congo",
        "code": "243"
    },
    {
        "country": "DK",
        "name": "Denmark",
        "code": "45"
    },
    {
        "country": "DJ",
        "name": "Djibouti",
        "code": "253"
    },
    {
        "country": "DM",
        "name": "Dominica",
        "code": "1"
    },
    {
        "country": "DO",
        "name": "Dominican Republic",
        "code": "1"
    },
    {
        "country": "EC",
        "name": "Ecuador",
        "code": "593"
    },
    {
        "country": "EG",
        "name": "Egypt",
        "code": "20"
    },
    {
        "country": "SV",
        "name": "El Salvador",
        "code": "503"
    },
    {
        "country": "GQ",
        "name": "Equatorial Guinea",
        "code": "240"
    },
    {
        "country": "ER",
        "name": "Eritrea",
        "code": "291"
    },
    {
        "country": "EE",
        "name": "Estonia",
        "code": "372"
    },
    {
        "country": "ET",
        "name": "Ethiopia",
        "code": "251"
    },
    {
        "country": "FK",
        "name": "Falkland Islands",
        "code": "500"
    },
    {
        "country": "FO",
        "name": "Faroe Islands",
        "code": "298"
    },
    {
        "country": "FM",
        "name": "Federated States of Micronesia",
        "code": "691"
    },
    {
        "country": "FJ",
        "name": "Fiji",
        "code": "679"
    },
    {
        "country": "FI",
        "name": "Finland",
        "code": "358"
    },
    {
        "country": "FR",
        "name": "France",
        "code": "33"
    },
    {
        "country": "GF",
        "name": "French Guiana",
        "code": "594"
    },
    {
        "country": "PF",
        "name": "French Polynesia",
        "code": "689"
    },
    {
        "country": "GA",
        "name": "Gabon",
        "code": "241"
    },
    {
        "country": "GE",
        "name": "Georgia",
        "code": "995"
    },
    {
        "country": "DE",
        "name": "Germany",
        "code": "49"
    },
    {
        "country": "GH",
        "name": "Ghana",
        "code": "233"
    },
    {
        "country": "GI",
        "name": "Gibraltar",
        "code": "350"
    },
    {
        "country": "GR",
        "name": "Greece",
        "code": "30"
    },
    {
        "country": "GL",
        "name": "Greenland",
        "code": "299"
    },
    {
        "country": "GD",
        "name": "Grenada",
        "code": "1"
    },
    {
        "country": "GP",
        "name": "Guadeloupe",
        "code": "590"
    },
    {
        "country": "GU",
        "name": "Guam",
        "code": "1"
    },
    {
        "country": "GT",
        "name": "Guatemala",
        "code": "502"
    },
    {
        "country": "GG",
        "name": "Guernsey",
        "code": "44"
    },
    {
        "country": "GN",
        "name": "Guinea",
        "code": "224"
    },
    {
        "country": "GY",
        "name": "Guyana",
        "code": "592"
    },
    {
        "country": "HT",
        "name": "Haiti",
        "code": "509"
    },
    {
        "country": "HN",
        "name": "Honduras",
        "code": "504"
    },
    {
        "country": "HK",
        "name": "Hong Kong",
        "code": "852"
    },
    {
        "country": "HU",
        "name": "Hungary",
        "code": "36"
    },
    {
        "country": "IS",
        "name": "Iceland",
        "code": "354"
    },
    {
        "country": "IN",
        "name": "India",
        "code": "91"
    },
    {
        "country": "ID",
        "name": "Indonesia",
        "code": "62"
    },
    {
        "country": "IR",
        "name": "Iran",
        "code": "98"
    },
    {
        "country": "IQ",
        "name": "Iraq",
        "code": "964"
    },
    {
        "country": "IE",
        "name": "Ireland",
        "code": "353"
    },
    {
        "country": "IM",
        "name": "Isle Of Man",
        "code": "44"
    },
    {
        "country": "IL",
        "name": "Israel",
        "code": "972"
    },
    {
        "country": "IT",
        "name": "Italy",
        "code": "39"
    },
    {
        "country": "JM",
        "name": "Jamaica",
        "code": "1"
    },
    {
        "country": "JP",
        "name": "Japan",
        "code": "81"
    },
    {
        "country": "JE",
        "name": "Jersey",
        "code": "44"
    },
    {
        "country": "JO",
        "name": "Jordan",
        "code": "962"
    },
    {
        "country": "KZ",
        "name": "Kazakhstan",
        "code": "7"
    },
    {
        "country": "KE",
        "name": "Kenya",
        "code": "254"
    },
    {
        "country": "KI",
        "name": "Kiribati",
        "code": "686"
    },
    {
        "country": "XK",
        "name": "Kosovo",
        "code": "383"
    },
    {
        "country": "KW",
        "name": "Kuwait",
        "code": "965"
    },
    {
        "country": "KG",
        "name": "Kyrgyzstan",
        "code": "996"
    },
    {
        "country": "LA",
        "name": "Laos",
        "code": "856"
    },
    {
        "country": "LV",
        "name": "Latvia",
        "code": "371"
    },
    {
        "country": "LB",
        "name": "Lebanon",
        "code": "961"
    },
    {
        "country": "LS",
        "name": "Lesotho",
        "code": "266"
    },
    {
        "country": "LR",
        "name": "Liberia",
        "code": "231"
    },
    {
        "country": "LY",
        "name": "Libya",
        "code": "218"
    },
    {
        "country": "LI",
        "name": "Liechtenstein",
        "code": "423"
    },
    {
        "country": "LT",
        "name": "Lithuania",
        "code": "370"
    },
    {
        "country": "LU",
        "name": "Luxembourg",
        "code": "352"
    },
    {
        "country": "MO",
        "name": "Macau",
        "code": "853"
    },
    {
        "country": "MK",
        "name": "Macedonia",
        "code": "389"
    },
    {
        "country": "MG",
        "name": "Madagascar",
        "code": "261"
    },
    {
        "country": "MW",
        "name": "Malawi",
        "code": "265"
    },
    {
        "country": "MY",
        "name": "Malaysia",
        "code": "60"
    },
    {
        "country": "MV",
        "name": "Maldives",
        "code": "960"
    },
    {
        "country": "ML",
        "name": "Mali",
        "code": "223"
    },
    {
        "country": "MT",
        "name": "Malta",
        "code": "356"
    },
    {
        "country": "MH",
        "name": "Marshall Islands",
        "code": "692"
    },
    {
        "country": "MQ",
        "name": "Martinique",
        "code": "596"
    },
    {
        "country": "MR",
        "name": "Mauritania",
        "code": "222"
    },
    {
        "country": "MU",
        "name": "Mauritius",
        "code": "230"
    },
    {
        "country": "YT",
        "name": "Mayotte",
        "code": "262"
    },
    {
        "country": "MX",
        "name": "Mexico",
        "code": "52"
    },
    {
        "country": "MD",
        "name": "Moldova",
        "code": "373"
    },
    {
        "country": "MC",
        "name": "Monaco",
        "code": "377"
    },
    {
        "country": "MN",
        "name": "Mongolia",
        "code": "976"
    },
    {
        "country": "ME",
        "name": "Montenegro",
        "code": "382"
    },
    {
        "country": "MS",
        "name": "Montserrat",
        "code": "1"
    },
    {
        "country": "MA",
        "name": "Morocco",
        "code": "212"
    },
    {
        "country": "MZ",
        "name": "Mozambique",
        "code": "258"
    },
    {
        "country": "MM",
        "name": "Myanmar",
        "code": "95"
    },
    {
        "country": "NA",
        "name": "Namibia",
        "code": "264"
    },
    {
        "country": "NR",
        "name": "Nauru",
        "code": "674"
    },
    {
        "country": "NP",
        "name": "Nepal",
        "code": "977"
    },
    {
        "country": "NL",
        "name": "Netherlands",
        "code": "31"
    },
    {
        "country": "NC",
        "name": "New Caledonia",
        "code": "687"
    },
    {
        "country": "NZ",
        "name": "New Zealand",
        "code": "64"
    },
    {
        "country": "NI",
        "name": "Nicaragua",
        "code": "505"
    },
    {
        "country": "NE",
        "name": "Niger",
        "code": "227"
    },
    {
        "country": "NG",
        "name": "Nigeria",
        "code": "234"
    },
    {
        "country": "NU",
        "name": "Niue",
        "code": "683"
    },
    {
        "country": "NF",
        "name": "Norfolk Island",
        "code": "672"
    },
    {
        "country": "KP",
        "name": "North Korea",
        "code": "850"
    },
    {
        "country": "MP",
        "name": "Northern Mariana Islands",
        "code": "1"
    },
    {
        "country": "NO",
        "name": "Norway",
        "code": "47"
    },
    {
        "country": "OM",
        "name": "Oman",
        "code": "968"
    },
    {
        "country": "PK",
        "name": "Pakistan",
        "code": "92"
    },
    {
        "country": "PW",
        "name": "Palau",
        "code": "680"
    },
    {
        "country": "PS",
        "name": "Palestine",
        "code": "970"
    },
    {
        "country": "PA",
        "name": "Panama",
        "code": "507"
    },
    {
        "country": "PG",
        "name": "Papua New Guinea",
        "code": "675"
    },
    {
        "country": "PY",
        "name": "Paraguay",
        "code": "595"
    },
    {
        "country": "PE",
        "name": "Peru",
        "code": "51"
    },
    {
        "country": "PH",
        "name": "Philippines",
        "code": "63"
    },
    {
        "country": "PL",
        "name": "Poland",
        "code": "48"
    },
    {
        "country": "PT",
        "name": "Portugal",
        "code": "351"
    },
    {
        "country": "PR",
        "name": "Puerto Rico",
        "code": "1"
    },
    {
        "country": "QA",
        "name": "Qatar",
        "code": "974"
    },
    {
        "country": "CG",
        "name": "Republic of the Congo",
        "code": "242"
    },
    {
        "country": "RO",
        "name": "Romania",
        "code": "40"
    },
    {
        "country": "RU",
        "name": "Russia",
        "code": "7"
    },
    {
        "country": "RW",
        "name": "Rwanda",
        "code": "250"
    },
    {
        "country": "SH",
        "name": "Saint Helena",
        "code": "290"
    },
    {
        "country": "KN",
        "name": "Saint Kitts and Nevis",
        "code": "1"
    },
    {
        "country": "MF",
        "name": "Saint Martin",
        "code": "590"
    },
    {
        "country": "PM",
        "name": "Saint Pierre and Miquelon",
        "code": "508"
    },
    {
        "country": "VC",
        "name": "Saint Vincent and the Grenadines",
        "code": "1"
    },
    {
        "country": "WS",
        "name": "Samoa",
        "code": "685"
    },
    {
        "country": "SM",
        "name": "San Marino",
        "code": "378"
    },
    {
        "country": "ST",
        "name": "Sao Tome and Principe",
        "code": "239"
    },
    {
        "country": "SA",
        "name": "Saudi Arabia",
        "code": "966"
    },
    {
        "country": "SN",
        "name": "Senegal",
        "code": "221"
    },
    {
        "country": "RS",
        "name": "Serbia",
        "code": "381"
    },
    {
        "country": "SC",
        "name": "Seychelles",
        "code": "248"
    },
    {
        "country": "SL",
        "name": "Sierra Leone",
        "code": "232"
    },
    {
        "country": "SG",
        "name": "Singapore",
        "code": "65"
    },
    {
        "country": "SX",
        "name": "Sint Maarten",
        "code": "1"
    },
    {
        "country": "SK",
        "name": "Slovakia",
        "code": "421"
    },
    {
        "country": "SI",
        "name": "Slovenia",
        "code": "386"
    },
    {
        "country": "SB",
        "name": "Solomon Islands",
        "code": "677"
    },
    {
        "country": "SO",
        "name": "Somalia",
        "code": "252"
    },
    {
        "country": "ZA",
        "name": "South Africa",
        "code": "27"
    },
    {
        "country": "KR",
        "name": "South Korea",
        "code": "82"
    },
    {
        "country": "SS",
        "name": "South Sudan",
        "code": "211"
    },
    {
        "country": "ES",
        "name": "Spain",
        "code": "34"
    },
    {
        "country": "LK",
        "name": "Sri Lanka",
        "code": "94"
    },
    {
        "country": "SD",
        "name": "Sudan",
        "code": "249"
    },
    {
        "country": "SR",
        "name": "Suriname",
        "code": "597"
    },
    {
        "country": "SZ",
        "name": "Swaziland",
        "code": "268"
    },
    {
        "country": "SE",
        "name": "Sweden",
        "code": "46"
    },
    {
        "country": "CH",
        "name": "Switzerland",
        "code": "41"
    },
    {
        "country": "SY",
        "name": "Syria",
        "code": "963"
    },
    {
        "country": "TW",
        "name": "Taiwan",
        "code": "886"
    },
    {
        "country": "TJ",
        "name": "Tajikistan",
        "code": "992"
    },
    {
        "country": "TZ",
        "name": "Tanzania",
        "code": "255"
    },
    {
        "country": "TH",
        "name": "Thailand",
        "code": "66"
    },
    {
        "country": "BS",
        "name": "The Bahamas",
        "code": "1"
    },
    {
        "country": "GM",
        "name": "The Gambia",
        "code": "220"
    },
    {
        "country": "TG",
        "name": "Togo",
        "code": "228"
    },
    {
        "country": "TK",
        "name": "Tokelau",
        "code": "690"
    },
    {
        "country": "TO",
        "name": "Tonga",
        "code": "676"
    },
    {
        "country": "TT",
        "name": "Trinidad and Tobago",
        "code": "1"
    },
    {
        "country": "TN",
        "name": "Tunisia",
        "code": "216"
    },
    {
        "country": "TR",
        "name": "Turkey",
        "code": "90"
    },
    {
        "country": "TM",
        "name": "Turkmenistan",
        "code": "993"
    },
    {
        "country": "TC",
        "name": "Turks and Caicos Islands",
        "code": "1"
    },
    {
        "country": "TV",
        "name": "Tuvalu",
        "code": "688"
    },
    {
        "country": "UG",
        "name": "Uganda",
        "code": "256"
    },
    {
        "country": "UA",
        "name": "Ukraine",
        "code": "380"
    },
    {
        "country": "AE",
        "name": "United Arab Emirates",
        "code": "971"
    },
    {
        "country": "GB",
        "name": "United Kingdom",
        "code": "44"
    },
    {
        "country": "US",
        "name": "United States",
        "code": "1"
    },
    {
        "country": "UY",
        "name": "Uruguay",
        "code": "598"
    },
    {
        "country": "VI",
        "name": "US Virgin Islands",
        "code": "1"
    },
    {
        "country": "UZ",
        "name": "Uzbekistan",
        "code": "998"
    },
    {
        "country": "VU",
        "name": "Vanuatu",
        "code": "678"
    },
    {
        "country": "VA",
        "name": "Vatican City",
        "code": "39"
    },
    {
        "country": "VE",
        "name": "Venezuela",
        "code": "58"
    },
    {
        "country": "VN",
        "name": "Vietnam",
        "code": "84"
    },
    {
        "country": "WF",
        "name": "Wallis and Futuna",
        "code": "681"
    },
    {
        "country": "EH",
        "name": "Western Sahara",
        "code": "212"
    },
    {
        "country": "YE",
        "name": "Yemen",
        "code": "967"
    },
    {
        "country": "ZM",
        "name": "Zambia",
        "code": "260"
    },
    {
        "country": "ZW",
        "name": "Zimbabwe",
        "code": "263"
    }
];
