import {EventEmitter, Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {CartProduct} from '../models/cart-product';
import {Product} from '../models/product';
import {ShopService} from './shop.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  slug: string;
  products: Product[] = [];
  cartItemsChanged: EventEmitter<any> = new EventEmitter();

  constructor(private storageService: StorageService,
              private shopService: ShopService) {
    this.shopService.shopUpdated.subscribe(data => {
      this.products = data.products;
      this.cartItemsChanged.emit(this.all(this.slug));
    });
  }

  all(slug: string): CartProduct[] {
    this.slug = slug;
    const cartProducts = this.storageService.getCartProducts(slug);
    cartProducts.map(cartProduct => {
      const product = this.products.find(x => x.id === cartProduct.product_id);
      if (product) cartProduct.product = product;
    });

    return cartProducts.filter(x => x.product !== null && x.product !== undefined);
  }

  add(product: Product, qty: number, slug: string): void {
    this.slug = slug;
    const cartProducts = this.all(slug);
    const objIndex = cartProducts.findIndex(cartProduct => cartProduct.product_id === product.id);
    if (objIndex === -1) {
      // product not in cart
      const newCartProduct = new CartProduct(null, qty, product.id);
      cartProducts.unshift(newCartProduct);
    } else {
      // product in cart, change qty
      cartProducts[objIndex].quantity += qty;
    }
    this.update(cartProducts, slug);
  }

  updateCart(product: Product, qty: number, slug: string): void {
    this.slug = slug;
    if (qty <= 0) {
      this.delete(product, slug);
      return;
    }
    const cartProducts = this.all(slug);
    const objIndex = cartProducts.findIndex(cartProduct => cartProduct.product_id === product.id);
    if (objIndex === -1) {
      // product not in cart
      const newCartProduct = new CartProduct(null, qty, product.id);
      cartProducts.unshift(newCartProduct);
    } else {
      // product in cart, change qty
      cartProducts[objIndex].quantity = qty;
    }
    this.update(cartProducts, slug);
  }

  delete(product: Product, slug: string): void {
    this.slug = slug;
    let cartProducts = this.all(slug);
    cartProducts = cartProducts.filter(cartProduct => cartProduct.product_id !== product.id);
    this.update(cartProducts, slug);
  }

  emptyCart(slug: string): void {
    this.slug = slug;
    this.update([], slug);
  }

  update(cartProducts: CartProduct[], slug): void {
    this.slug = slug;
    const tmp = [];
    cartProducts.forEach(x => {
      tmp.push(CartProduct.toStorage(x));
    });
    this.storageService.setCartProducts(tmp, slug);
    this.cartItemsChanged.emit(this.all(slug));
  }
}
