export class Shop {
  name: string;
  banner: string;
  logo: string;
  order_via_whatsapp: boolean;
  order_via_signal: boolean;

  static fromJSON(json: any): Shop {
    const object = new Shop();
    object.name = json.name;
    object.banner = json.banner;
    object.logo = json.logo;
    object.order_via_whatsapp = json.order_via_whatsapp;
    object.order_via_signal = json.order_via_signal;
    return object;
  }

  constructor() {
    this.name = 'iSlash.io Shop';
  }
}
