import { timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TimeoutError } from 'rxjs';
import { PopupService } from './popup.service';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiService {

    suppressErrorCodePopup = ["GENL_0001"];

    constructor(private http: HttpClient,
        private router: Router,
        private dialog: MatDialog,
        private popupService: PopupService,
        private storageService: StorageService,
        private translateService: TranslateService) {
    }

    method(method: string, url: string, params: any, data: any, success?, error?, timeoutMillisecond?, responseType?): void {
        let headers = new HttpHeaders();
        // let user = this.storageService.getCurrentUser();
        // if (user)
        //     headers = headers.set('X-USER-TOKEN', user.token);
        // else
        //     headers = headers.set('X-USER-TOKEN', environment.guestToken);

        if (!timeoutMillisecond)
            timeoutMillisecond = 60000;

        this.http.request(method, environment.apiRoot + "/" + url, {
            headers: headers,
            params: params,
            body: data,
            responseType: responseType
        }).pipe(timeout(timeoutMillisecond))
            .subscribe(
                res => {
                    if (success !== undefined)
                        success(res);
                },
                err => {
                    if (err instanceof TimeoutError) {
                        this.translateService.get(['toast-message.server-connection-timeout']).subscribe(res => {

                            this.popupService.show({
                                template: res['toast-message.server-connection-timeout'],
                                buttons: [
                                    {
                                        text: 'OK',
                                    }
                                ]
                            });
                        })

                        if (error !== undefined)
                            error();
                    }
                    else if (err instanceof HttpErrorResponse) {
                        const body = err.error;
                        if (!body || !body.meta || !body.meta.code) {
                            if (error !== undefined)
                                error(body);
                        }
                        else if (body && body.meta && body.meta.code) {
                            if (body.meta.code === "GENL_0404") {
                                window.location.href = '/404';
                            } else if (body.meta.code === "GENL_0004") {
                                this.popupService.show({
                                    code: "GENL_0004",
                                    errors: body.data.error,
                                    buttons: [
                                        {
                                            text: 'OK'
                                        }
                                    ]
                                });
                            } else if (this.suppressErrorCodePopup.indexOf(body.meta.code) < 0) {
                                this.popupService.show({
                                    template: body.meta.message,
                                    buttons: [
                                        {
                                            text: 'OK',
                                        }
                                    ]
                                });
                            }

                            if (error !== undefined)
                                error(body);
                        }
                        else {
                            if (error !== undefined)
                                error(body);
                        }

                    }
                    else {
                        if (error !== undefined)
                            error(err.toString());
                    }
                }
            );
    }
}
