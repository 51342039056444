<div class="main">
  <section class="module">
    <div class="container">
      <div *ngIf="cartItems && cartItems.length === 0" class="row">
        <div class="col-sm-12">
          <h3 class="cart-is-empty">{{ 'cart.your-cart-is-empty' | translate }}</h3>
          <div class="font-alt mt-30 continue-to-shop"><a class="btn btn-border-d btn-round" [routerLink]="'/' + slug">{{ 'cart.continue-to-shop' | translate }}</a></div>
        </div>
      </div>
      <ng-container *ngIf="cartItems && cartItems.length > 0">
        <div class="row">
          <div class="col-sm-6 col-sm-offset-3">
            <h1 class="module-title font-alt">{{ 'cart.shopping-cart' | translate }}</h1>
          </div>
        </div>
        <hr class="divider-w pt-20">
        <div class="row">
          <div class="col-sm-12">
            <table  class="table table-striped table-border checkout-table">
              <tbody>
              <tr>
                <th class="hidden-xs">{{ 'cart.product' | translate }}</th>
                <th>{{ 'cart.product-name' | translate }}</th>
                <th class="hidden-xs">{{ 'cart.price' | translate }}</th>
                <th>{{ 'cart.quantity' | translate }}</th>
                <th>{{ 'cart.total' | translate }}</th>
                <th>{{ 'cart.remove' | translate }}</th>
              </tr>
              <tr *ngFor="let cartItem of cartItems">
                <td class="hidden-xs">
                  <a *ngIf="cartItem.product" [routerLink]="'/' + slug + '/product/' + cartItem.product.id"><img [src]="cartItem.product.thumbnail ? cartItem.product.thumbnail : 'assets/images/product-image-placeholder.png'" [alt]="cartItem.product.name"/></a>
                </td>
                <td>
                  <a [routerLink]="'/' + slug + '/product/' + cartItem.product.id"><h5 class="product-title font-alt">{{ cartItem.product ? cartItem.product.name : ''}}</h5></a>
                </td>
                <td class="hidden-xs">
                  <h5 class="product-title font-alt">{{ currency }} {{ cartItem.product ? cartItem.product.price.toLocaleString() : ''}}</h5>
                </td>
                <td>
                  <input [(ngModel)]="cartItem.quantity" [class.invalid]="!qtyIsValid(cartItem)" class="form-control" type="number" name="qty" min="1" (change)="updateQty(cartItem)" appNumberOnly/>
                </td>
                <td>
                  <h5 *ngIf="cartItem.product" class="product-title font-alt">{{ currency }} {{ (cartItem.quantity * cartItem.product.price).toLocaleString() }}</h5>
                </td>
                <td class="pr-remove"><a href="#" title="{{ 'cart.remove' | translate }}" (click)="removeProduct(cartItem, $event)"><i class="fa fa-times"></i></a></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="divider-w">
        <div class="row mt-70">
          <div class="col-sm-7">
            <div class="shop-Cart-totalbox">
              <h4 class="font-alt">{{ 'cart.contact-info' | translate }}</h4>
              <div class="row">
                <div class="col-sm-10">
                  <div class="form-group">
                    <label>{{ 'cart.name' | translate }}*</label>
                    <input class="form-control" [class.invalid]="submitted && !isNotEmpty(name)" [(ngModel)]="name" type="text" name="name" placeholder="{{ 'cart.name' | translate }}"/>
                  </div>
                  <div class="form-group">
                    <label>{{ 'cart.contact-method' | translate }}*</label>
                    <select [class.invalid]="submitted && !isNotEmpty(channel)" [(ngModel)]="channel" name="contact-method" class="form-control contact-method">
                      <option value="" disabled>{{ 'cart.please-select' | translate }}</option>
                      <option *ngIf="shop && shop.order_via_whatsapp" value="whatsapp">WhatsApp</option>
                      <option *ngIf="shop && shop.order_via_signal" value="signal">Signal</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="row no-gutters">
                      <div class="col-xs-5">
                        <select name="callingCode" [(ngModel)]="selectedCallingCode" class="form-control contact-method">
                          <option *ngFor="let callingCode of callingCodes" [value]="callingCode.code">
                            {{ callingCode.name }} (+{{ callingCode.code}})
                          </option>
                        </select>
                      </div>
                      <div class="col-xs-7">
                        <input class="form-control" [class.invalid]="submitted && !isNotEmpty(contactNumber)" [(ngModel)]="contactNumber" type="text" name="contactNumber" placeholder="{{ 'cart.phone-number' | translate }}" appNumberOnly/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="shop-Cart-totalbox">
              <h4 class="font-alt">{{ 'cart.cart-total' | translate }}</h4>
              <table class="table table-striped table-border checkout-table">
                <tbody>
                <tr class="shop-Cart-totalprice">
                  <th>{{ 'cart.total' | translate }}</th>
                  <td>{{ currency }} {{ total_str }}</td>
                </tr>
                </tbody>
              </table>
              <button class="btn btn-lg btn-block btn-round btn-d payment-button" type="submit" [appLoadingButton]="submitting" (click)="submit()">{{ 'cart.proceed-to-payment' | translate }}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</div>
