import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'productFilter'
})
export class ProductFilterPipe implements PipeTransform {

  transform(items: any[], searchString: string): any {
    if (!items || !searchString) {
      return items;
    }

    return items.filter(item => (item.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) || (item.code && item.code.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) || (item.price.toString().indexOf(searchString) !== -1));
  }
}
