import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShopService} from '../../../services/shop.service';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {Shop} from '../../../models/shop';
import {Product} from '../../../models/product';
import {CartProduct} from '../../../models/cart-product';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  slug: string;
  shop: Shop;
  products: Product[];
  currency: string;
  cartItems: CartProduct[];
  otherProducts: Product[];

  product: Product;
  productId: number;
  addedToCart = false;
  qty = 1;

  shopUpdateSubscription;
  cartItemsUpdateSubscription;
  routeParamMapSubscription;

  constructor(private shopService: ShopService,
              private shoppingCartService: ShoppingCartService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.updateData(this.shopService);
    this.shopUpdateSubscription = this.shopService.shopUpdated.subscribe(data => {
      this.updateData(data);
    });

    this.cartItemsUpdateSubscription = this.shoppingCartService.cartItemsChanged.subscribe(cartItems => this.cartItems = cartItems);

    this.routeParamMapSubscription = this.route.paramMap.subscribe((param) => {
      this.productId = parseInt(param['params']['productId'], 10);
      this.updateData(this.shopService);
    });
  }

  ngOnDestroy(): void {
    this.shopUpdateSubscription.unsubscribe();
    this.routeParamMapSubscription.unsubscribe();
  }

  updateData(data): void {
    this.slug = data.slug;
    this.shop = data.shop;
    this.products = data.products;
    this.currency = data.currency;
    this.product = this.products.find(product => product.id === this.productId);
    this.otherProducts = this.getOtherProducts();
    if (this.shopService.initialized && this.productId !== undefined && this.product === undefined) {
      window.location.href = '/404';
    }
    this.cartItems = this.shoppingCartService.all(this.slug);
  }

  getOtherProducts(): Product[] {
    return this.products
      .filter(product => product.id !== this.productId)
      .sort( () => .5 - Math.random() )
      .slice(0, 4);
  }

  addToCart(product): void {
    this.shoppingCartService.add(product, this.qty, this.slug);
    this.addedToCart = true;
    setTimeout(() => {
      this.addedToCart = false;
    }, 5000);
  }

  addOtherToCart(product: Product): void {
    if (this.isInCart(product)) {
      this.router.navigate(['/' + this.slug + '/cart']);
    } else {
      this.shoppingCartService.add(product, 1, this.slug);
    }
  }

  isInCart(product: Product): boolean {
    return this.cartItems.filter(cartItem => cartItem.product_id === product.id).length > 0;
  }
}
