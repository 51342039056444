import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../../models/product';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss']
})
export class ProductListItemComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: string;
  @Input() isInCart: boolean;
  @Output() detailClicked: EventEmitter<any> = new EventEmitter();
  @Output() addToCartClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  addToCart(product: Product, e): void {
    e.preventDefault();
    e.stopPropagation();
    this.addToCartClicked.emit(product);
  }

  detail(product: Product, e): void {
    e.preventDefault();
    this.detailClicked.emit(product);
  }
}
