<div class="main">
  <section *ngIf="product" class="module">
    <div class="container">
      <div class="row">
        <div *ngIf="product.image" class="col-sm-6 mb-sm-40 product-image">
          <a class="gallery" target="_blank" [href]="product.image">
            <img [src]="product.thumbnail" [alt]="product.name"/>
          </a>
        </div>
        <div [class.col-sm-6]="product.image" [class.col-sm-12]="!product.image">
          <div class="row">
            <div class="col-sm-12">
              <h1 class="product-title font-alt">{{ product.name }}</h1>
            </div>
          </div>
          <div class="row mb-20">
            <div class="col-sm-12">
              <div class="price font-alt"><span class="amount">{{ currency }} {{ product.price.toLocaleString() }}</span></div>
            </div>
          </div>
          <div class="row mb-20">
            <div class="col-sm-12">
              <div class="description">
                <p [innerText]="product.description"></p>
              </div>
            </div>
          </div>
          <div class="row mb-20" [class.no-image]="!product.image">
            <div class="col-sm-4 mb-sm-20">
              <input [(ngModel)]="qty" class="form-control input-lg" type="number" min="1" required="required"/>
            </div>
            <div class="col-sm-8">
              <a *ngIf="!addedToCart" class="btn btn-lg btn-block btn-round btn-b" (click)="addToCart(product)">{{ 'common.add-to-cart' | translate }}</a>
              <a *ngIf="addedToCart" class="btn btn-lg btn-block btn-round btn-b view-in-cart" [routerLink]="'/' + slug + '/cart'"><mat-icon>checkmark</mat-icon> {{ 'common.view-in-cart' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="otherProducts.length > 0">
    <hr class="divider-w">
    <section class="module-small">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-sm-offset-3">
            <h2 class="module-title font-alt">{{ 'product.other-products' | translate }}</h2>
          </div>
        </div>
        <div class="row multi-columns-row">
            <div *ngFor="let product of otherProducts" class="col-sm-6 col-md-3 col-lg-3">
              <a [routerLink]="'/' + slug + '/product/' + product.id">
                <app-product-list-item [product]="product" [isInCart]="isInCart(product)" [currency]="currency" (addToCartClicked)="addOtherToCart($event)"></app-product-list-item>
              </a>
            </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
