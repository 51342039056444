import {Product} from './product';

export class CartProduct {
  product: Product;
  product_id: number;
  quantity: number;

  static fromJSON(json: any): CartProduct {
    const object = new CartProduct();
    object.product_id = json.product_id;
    object.quantity = json.quantity;
    return object;
  }

  static toStorage(cartProduct: CartProduct): object {
    const object = JSON.parse(JSON.stringify(cartProduct));
    delete object.product;
    return object;
  }

  public constructor(product?: Product, qty?: number, productId?: number) {
    this.product = product ? product : null;
    this.product_id = productId ? productId : null;
    this.quantity = qty ? qty : 0;
  }

  get total_price(): number {
    return this.product.price * this.quantity;
  }
}
