import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './page/home/home.component';
import {PageComponent} from './page/page.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ProductComponent} from './page/product/product.component';
import {CartComponent} from './page/cart/cart.component';

const routes: Routes = [
  {path: '404', component: NotFoundComponent},
  {
    path: ':shop',
    component: PageComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'product/:productId', component: ProductComponent},
      {path: 'cart', component: CartComponent}
    ]
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
