<div class="page-loader">
  <div class="loader">Loading...</div>
</div>
<nav class="navbar navbar-custom navbar-fixed-top" role="navigation">
  <div class="container">
    <div class="navbar-header">
      <a class="navbar-brand" [class.logo]="shop && shop.logo" [routerLink]="'/' + slug">
        <img *ngIf="shop && shop.logo" [src]="shop.logo" [alt]="shop.name">
        <ng-container *ngIf="shop && !shop.logo">
          {{ shop.name }}
        </ng-container>
      </a>

      <div class="mobile">
        <a [routerLink]="'/' + slug + '/cart'">
          <mat-icon [matBadgeHidden]="cartProducts.length === 0" [matBadge]="cartProductsCount" matBadgeColor="warn">shopping_cart</mat-icon>
        </a>
      </div>
    </div>
    <div class="collapse navbar-collapse desktop" id="custom-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="cart">
          <a [routerLink]="'/' + slug + '/cart'">
            {{ 'header.shopping-cart' | translate }} <mat-icon [matBadgeDisabled]="cartProducts.length === 0" [matBadge]="cartProductsCount" matBadgeColor="warn">shopping_cart</mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
