import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from '../../services/app-config.service';
import {TranslateService} from '@ngx-translate/core';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {CartProduct} from '../../models/cart-product';
import {Shop} from '../../models/shop';
import {ActivatedRoute} from '@angular/router';
import {ShopService} from '../../services/shop.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  slug = '';
  shop = new Shop();
  cartProducts: CartProduct[] = [];

  cartItemsChangeSubscription;
  routeParamMapSubscription;

  constructor(private appConfigService: AppConfigService,
              private translateService: TranslateService,
              private shoppingCartService: ShoppingCartService,
              private route: ActivatedRoute,
              private shopService: ShopService,
              private title: Title) {
  }

  ngOnInit(): void {
    this.cartItemsChangeSubscription = this.shoppingCartService.cartItemsChanged.subscribe(cartProducts => {
      this.cartProducts = cartProducts;
    });

    this.routeParamMapSubscription = this.route.paramMap.subscribe((param) => {
      this.slug = param['params']['shop'];
      this.shopService.refresh(this.slug, (shop, products) => {
        this.shop = shop;
        if (environment.code === 'PROD') {
          this.title.setTitle(this.shop.name);
        } else {
          this.title.setTitle('[' + environment.code + '] ' + this.shop.name);
        }
        this.cartProducts = this.shoppingCartService.all(this.slug);
      });
    });
  }

  ngOnDestroy(): void {
    this.cartItemsChangeSubscription.unsubscribe();
  }

  changeLang(lang: string, e: Event): void {
    e.preventDefault();
    this.appConfigService.setLanguage(lang, true);
  }

  get cartProductsCount(): number {
    return this.cartProducts.length === 0 ? 0 : this.cartProducts.map(cartProduct => cartProduct.quantity).reduce((a, b) => a + b);
  }


}
