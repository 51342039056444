<div class="page-loader">
  <div class="loader">Loading...</div>
</div>
<section class="home-section home-parallax home-fade home-full-height bg-dark bg-dark-30" id="home">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="font-alt mb-30 titan-title-size-4">Error 404</div>
      <div class="font-alt">The requested URL was not found on this server.</div>
      <div class="font-alt mt-30"><a class="btn btn-border-w btn-round" href="https://islash.io">Back to iSlash.io</a></div>
    </div>
  </div>
</section>
