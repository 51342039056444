import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AppConfigService} from '../../services/app-config.service';
import {Shop} from '../../models/shop';
import {ShopService} from '../../services/shop.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  // shop = new Shop();
  shopSlug = '';
  routeParamMapSubscription;
  // shopUpdateSubscription;

  constructor(private route: ActivatedRoute,
              private appConfigService: AppConfigService,
              private shopService: ShopService,
              private router: Router) {}

  ngOnInit(): void {
    // this.shop = this.shopService.shop;
    // this.products = this.shopService.products;
    // this.shopUpdateSubscription = this.shopService.shopUpdated.subscribe(data => {
    //   console.log(data);
    //   this.shop = data.shop;
    // });
    // this.routeParamMapSubscription = this.route.paramMap.subscribe((param) => {
    //   this.shopSlug = param['params']['shop'];
    //   // this.title = this.shopSlug;
    //   console.log(param);
    //   // this.titleService.setTitle(this.title);
    //   // this.appConfigService.getShopConfig(this.shopSlug);
    // });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    // this.shopUpdateSubscription.unsubscribe();
  }

}
