<div class="main">

  <!-- <section *ngIf="shop && shop.banner" class="module bg-dark-60 shop-page-header" [style.background-image]="shop ? 'url(' + shop.banner + ')' : ''">
  </section> -->

  <img *ngIf="shop && shop.banner" class="img-fluid banner" [src]="shop.banner">

  <section class="module-small">
    <div class="container">
      <div class="row mb-40">
        <div class="col-sm-4 pb-10">
          <select class="form-control" [(ngModel)]="sortBy">
            <option value="default">{{ 'home.sorting' | translate }}</option>
            <option value="name-a-to-z">{{ 'home.name-a-z' | translate }}</option>
            <option value="name-z-to-a">{{ 'home.name-z-a' | translate }}</option>
            <option value="price-low-to-high">{{ 'home.price-low-to-high' | translate }}</option>
            <option value="price-high-to-low">{{ 'home.price-high-to-low' | translate }}</option>
          </select>
        </div>
        <div class="col-sm-4 col-sm-offset-4">
          <input type="text" class="form-control" placeholder="{{ 'home.search' | translate }}" [(ngModel)]="keyword">
        </div>
      </div>
      <div class="row">
        <div *ngFor="let product of ((products | productFilter: keyword) | productSort: sortBy) | slice: (page-1) * pageSize : page * pageSize; index as i;" class="col-sm-6 col-md-4 col-lg-4 col-xs-6">
          <a [routerLink]="'/' + slug + '/product/' + product.id" [title]="product.name">
            <app-product-list-item [product]="product" [currency]="currency" [isInCart]="isInCart(product)" (addToCartClicked)="addToCart($event)"></app-product-list-item>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="pagination font-alt">
            <a href="#" (click)="prevPage($event)"><i class="fa fa-angle-left"></i></a>
            <a *ngFor="let i of pages" [class.active]="i == page" href="#" (click)="changePage(i, $event)">{{ i }}</a>
            <a href="#" (click)="nextPage($event)"><i class="fa fa-angle-right"></i></a></div>
        </div>
      </div>
    </div>
  </section>
</div>



