import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'productSort'
})
export class ProductSortPipe implements PipeTransform {

  transform(items: any[], sortBy: string): any {
    if (!items || !sortBy) {
      return items;
    }

    if (sortBy === 'name-a-to-z') {
      items.sort((a, b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });
    } else if (sortBy === 'name-z-to-a') {
      items.sort((a, b) => {
        if(b.name < a.name) { return -1; }
        if(b.name > a.name) { return 1; }
        return 0;
      });
    } else if (sortBy === 'price-low-to-high') {
      items.sort((a, b) => {
        return a.price - b.price;
      });
    } else if (sortBy === 'price-high-to-low') {
      items.sort((a, b) => {
        return b.price - a.price;
      });
    }

    return items;
  }
}
