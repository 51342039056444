import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PopupService} from '../services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  popup = null;

  popupChangedSubscription;

  constructor(private router: Router,
              private translateService: TranslateService,
              private popupService: PopupService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.popupChangedSubscription = this.popupService.popupChanged.subscribe((config) => {
      this.popup = {
        template: config.template,
        code: config.code,
        errors: config.errors,
        buttons: []
      };

      if (config.buttons) {
        this.translateService.get(['products.yes', 'products.no']).subscribe(res => {
          config.buttons.forEach((button, i) => {
            let style = 'primary';
            let text = res['products.yes'];
            if (i === 1) {
              style = 'default';
              text = res['products.no'];
            }
            if (button.type)
              style = button.type;

            if (button.text)
              text = button.text;

            this.popup.buttons.push({
              text: text,
              style: style,
              callback: button.callback
            });
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.popupChangedSubscription.unsubscribe();
  }

  popupButtonClick(button): void {
    if (button.callback)
      button.callback();

    this.popup = null;
  }

}
